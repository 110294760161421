import { OptionalField, BudgetViewDocument } from '@og-shared/types';
import {
  collection,
  getFirestore,
  query,
  orderBy,
  doc,
  setDoc,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';

function getPath(groupId: string, budgetViewId?: string) {
  return `groups/${groupId}/budget-views/${budgetViewId ?? ''}`;
}

export async function firestoreGetBudgetViews(groupId: string) {
  const path = getPath(groupId);
  const collectionRef = collection(getFirestore(), path);
  const q = query(collectionRef, orderBy('order', 'asc'));
  const snap = await getDocs(q);
  const budgetView = snap.docs.map(doc => doc.data() as BudgetViewDocument);
  return budgetView;
}

export async function firestoreSaveBudgetViewDocument(
  groupId: string,
  budgetView: OptionalField<BudgetViewDocument, 'id'>
) {
  if (!budgetView.id) {
    const newDocRef = doc(collection(getFirestore(), getPath(groupId)));
    budgetView.id = newDocRef.id;
  }
  const path = getPath(groupId, budgetView.id);

  const docRef = doc(getFirestore(), path);
  await setDoc(docRef, budgetView, { merge: true });
  return budgetView as BudgetViewDocument;
}

export async function firestoreDeleteBudgetViewDocument(
  groupId: string,
  budgetViewId: string
) {
  const path = getPath(groupId, budgetViewId);
  const docRef = doc(getFirestore(), path);
  return deleteDoc(docRef);
}
